import React from "react"
import Content from "../../../components/Content"
import Layout from "../../../components/Layout"
import ResultsMenu from "../../../components/ResultsMenu"
import Csv from "../../../components/Csv"

export default function Results() {
  return (
    <Layout>
      <ResultsMenu year={2024} />
      <Content>
        {" "}
        <Csv
          csvUrl="/sprint-results-2024.csv"
          structureFromFile={true}
          separator=","
        />
      </Content>
    </Layout>
  )
}
